<template>
    <div class="hospitals">
    <PreLoader v-if="isLoading"></PreLoader>
    <v-card>
      <v-card-title></v-card-title>
      <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
          <v-row>
            <v-col md="3" sm="3">
              <v-toolbar-title>Список Постов</v-toolbar-title>
            </v-col>
            <v-col md="4" sm="4">
              <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск..."
              solo
              dense
              hide-details
              ></v-text-field>
            </v-col>
            <v-col md="5" sm="5" align="end">
              <v-btn color="#F53C56"  class="hospitals__addbtn mb-2 mr-5" @click="dialog=true">Добавить <v-icon>mdi-plus</v-icon></v-btn>
              <!--<v-btn color="#40D6F2"  class="hospitals__btn mb-2 mr-5">Импорт</v-btn>
              <v-btn color="#7764E4" class="hospitals__btn mb-2 mr-5">Экспорт</v-btn>-->
            </v-col>
          </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.avatarurl="{ item }" style="padding:10px;">
              <v-img
                  style="border-radius:10px;"
                  :src="item.avatarUrl"
                  :lazy-src="item.avatarUrl"
                  max-height="90"
                  max-width="90"
              ></v-img>
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon
              md
              class="mr-2"
              @click="editItem(item)"
              color="primary"
            >
              mdi-pencil-box-outline
            </v-icon>
          <v-dialog :retain-focus="false"
            v-model="dialog2"
            max-width="500"
          >
          <template v-slot:activator="{ on }">
            <v-icon
              md v-on="on"
              @click="dialog2 =true"
              color="#F53C56"
            >
              mdi-delete
            </v-icon>
          </template>
          
            <v-card>
              <v-card-title class="headline">
                Вы действительно хотите удалить?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="dialog2=false"
                >
                  Отменить
                </v-btn>

                <v-btn
                  color="green darken-1"
                  text
                  @click.stop="deletePost(item)"
                >
                  Удалить
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialog"
        max-width="600px"
      >
        <v-card>
          <v-card-title style="background:orange;">
            <span class="headline" style="font-weight:500;">Новый пост</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  style="padding-top:10px !important;padding-bottom:0px !important; paddin-left:10px;"
                >
                  <label>Загаловок поста</label>
                  <v-text-field
                    v-model="post.textTitle"
                    label="Загаловка текста"
                    solo
                    dense
                  ></v-text-field>
                </v-col>
                <v-col 
                  cols="12" 
                  md="12" 
                  sm="12"
                  style="padding-top:0px !important;padding-bottom:0px !important; paddin-left:10px;"
                >
                  <label>Текст поста</label>
                  <v-textarea
                    v-model="post.text"
                    label="Текст теста"
                    required
                    solo
                    dense
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12" sm="12" align="center">
                    <v-file-input
                        :rules="rules"
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Загрузите фото для поста"
                        prepend-inner-icon="mdi-camera"
                        solo
                        @change="onChange"
                    ></v-file-input>
                </v-col>
                <!--<v-col
                  cols="12"
                  sm="6"
                  
                >
                  <v-text-field
                    v-model="response.title"
                    label="Текст варианта"
                    solo
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12" align="center">
                    <v-btn color="orange" @click="addResponses" solo><v-icon>mdi-plus-box</v-icon>Добавить варианты</v-btn>
                </v-col>-->
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Отменить
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="addPost"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="editDialog"
        max-width="600px"
      >
        <v-card>
          <v-card-title style="background:orange;">
            <span class="headline" style="font-weight:500;">Новый пост</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  style="padding-top:10px !important;padding-bottom:0px !important; paddin-left:10px;"
                >
                  <label>Загаловок поста</label>
                  <v-text-field
                    v-model="postEdit.textTitle"
                    label="Загаловка текста"
                    solo
                    dense
                  ></v-text-field>
                </v-col>
                <v-col 
                  cols="12" 
                  md="12" 
                  sm="12"
                  style="padding-top:0px !important;padding-bottom:0px !important; paddin-left:10px;"
                >
                  <label>Текст поста</label>
                  <v-textarea
                    v-model="postEdit.text"
                    label="Текст теста"
                    required
                    solo
                    dense
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="12" sm="12" align="center">
                    <v-file-input
                        :rules="rules"
                        accept="image/png, image/jpeg, image/bmp"
                        placeholder="Загрузите фото для поста"
                        prepend-inner-icon="mdi-camera"
                        solo
                        @change="onChange"
                    ></v-file-input>
                </v-col>
                <v-col cols="12" md="12" sm="12" align="center">
                  <v-img
                    style="border-radius:10px;"
                    :src="postEdit.avatarUrl"
                    :lazy-src="postEdit.avatarUrl"
                    max-height="500"
                    max-width="500"
                  ></v-img>
                </v-col>
                <!--<v-col
                  cols="12"
                  sm="6"
                  
                >
                  <v-text-field
                    v-model="response.title"
                    label="Текст варианта"
                    solo
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" sm="12" align="center">
                    <v-btn color="orange" @click="addResponses" solo><v-icon>mdi-plus-box</v-icon>Добавить варианты</v-btn>
                </v-col>-->
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="white darken-1"
              text
              @click="editDialog = false"
              style="border:1px solid #ff1000;background:#ff1000;"
            >
              Отменить
            </v-btn>
            <v-btn
              color="white darken-1"
              text
              @click="editPost"
              style="border:1px solid #ffa600;background:#ffa600;"
            >
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>
<script>
import PreLoader from '../../components/preloader/PreLoader.vue';
import * as firebase from '@/firebase';
import "firebase/storage";
import moment from 'moment';
export default {
    components: {
    PreLoader,
  },
  name: "Hospitals",
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Фото',
            align: 'start',
            value: 'avatarurl',
          },
          { text: 'Загаловок', value: 'textTitle' },
          { text: '', value: 'action',sortable: false },
          
          
        ],
        close: false,
        dialog2:false,
        dialog:false,
        editDialog:false,
        locationId:0,
        isLoading:false,
        items:[],
        num:0,
        rules: [
            value => !value || value.size < 10000000 || 'Фото должно быть меньше 10MB!',
        ],
        post: {
            textTitle: '',
            avatarUrl: '',
            text:'',
            id:'',
            likes:0
        },
        postEdit: {
            textTitle: '',
            avatarUrl: '',
            text:'',
            id:'',
            likes:0
        },
        fileUrl: ''
      }
    },
    created() {
      this.getPosts();
    },           
    methods: {
        async getPosts() {
          this.isLoading = true;
          this.events = await firebase.postsCollection.get()
            .then((snapshot) => {
              snapshot.forEach((el) => {
                  this.items.push({...el.data()})
              });
            })
            this.isLoading = false;
        },
        deletePost (item) {
          firebase.postsCollection.doc(item.id).delete().then(() => {
              this.dialog2 = false;
              this.$toastr.s("Тест успешно удален!");
              this.items = [];
              this.getPosts();
              //return (snapshot.data())  
          }).catch(err=>{console.log(err)});
        },
        async onChange(e) {
            const file = e;
            const storageRef = firebase.storage.ref();
            const fileRef = storageRef.child('Uploads/'+ file.name)
            await fileRef.put(file);
            this.fileUrl = await fileRef.getDownloadURL();
        },
        async addPost () {
            this.post.avatarUrl = this.fileUrl;
            console.log(this.post);
            await firebase.postsCollection.add(this.post).then((snapshot) => {
              firebase.postsCollection.doc(snapshot.id).get().then((snapshot) => {
                const data = snapshot.data();
                firebase.postsCollection.doc(snapshot.id).set({
                  ...data,
                  id:snapshot.id,
                  date: moment(new Date()).format('DD.MM.YYYY')
                }).then(() => {
                  this.$toastr.s("Пост успешно добавлен!");
                })
              })
              this.dialog = false;
              this.items = [];
              this.getPosts();
              //return (snapshot.data())  
          }).catch(err=>{console.log(err)});
        },
        editItem(item){
          this.postEdit = item;
          this.editDialog = true;
        },
        async editPost() {
          await firebase.postsCollection.doc(this.postEdit.id).get().then((snapshot) => {
              //const data = snapshot.data();
              firebase.postsCollection.doc(snapshot.id).set({
                avatarUrl: this.postEdit.avatarUrl,
                likes: this.postEdit.likes,
                text: this.postEdit.text,
                textTitle: this.postEdit.textTitle,
                id:this.postEdit.id,
                date: moment(new Date()).format('DD.MM.YYYY')
              }).then(() => {
                this.$toastr.s("Тест успешно отредактирован!");
                this.items = [];
                this.editDialog = false;
                this.getPosts();
              })
          })
        }
        
    },
}
</script>
<style lang="scss" scoped>
.hospitals{
  &__btn{
    color:#FFFFFF;
  }
  &__addbtn{
    color:#FFFFFF;
  }
}
.theme--light.v-btn {
    color: rgba(255, 255, 255, 0.87);
}
</style>